import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import ReactMarkdown from "react-markdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import anime from "animejs/lib/anime.es.js"
import PropTypes from "prop-types"
import React from "react"
import { TRANSLATE_ANIME_CONFIG } from "../constants"
import dotsSquareImg from "../img/dots-square.png"
import { colors } from "../theme"
import AnimateOnSeen from "./animate-on-seen"
import PreviewCompatibleImage from "./preview-compatible-image"
import Video from "./video"

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: "100%",
  },
  topLeftBorder: {
    borderTopLeftRadius: 24,
  },
  borderRadius: {
    borderRadius: 10,
  },
  borderLeftRadius: {
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  borderRightRadius: {
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
  },
  noPadding: {
    padding: 0,
  },
  background: {
    opacity: 0.6,
  },
}))

const Benefit = ({
  className,
  title,
  description,
  featuredmedia,
  bullets,
  children,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const sm = useMediaQuery(theme.breakpoints.up("sm"))
  const md = useMediaQuery(theme.breakpoints.up("md"))

  const columns = []

  if (bullets && bullets.items) {
    const itemsPerColumn = Math.ceil(bullets.items.length / bullets.columns)

    let currentColumn = { items: [] }

    bullets.items.forEach((item, index) => {
      if (index % itemsPerColumn === 0) {
        if (currentColumn.items.length) {
          columns.push(currentColumn)
          currentColumn = { items: [] }
        }
      }

      currentColumn.items.push(item)
    })

    if (currentColumn.items.length) {
      columns.push(currentColumn)
    }
  }

  function renderText() {
    return (
      <Box
        py={4}
        px={4}
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <AnimateOnSeen
          animeConfig={{
            ...TRANSLATE_ANIME_CONFIG,
            targets: `.${className} .title`,
            delay: anime.stagger(100, { direction: "reverse" }),
          }}
        >
          {title && (
            <Typography
              className="title"
              variant="h3"
              color="textPrimary"
              gutterBottom
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              className="title"
              variant="body1"
              paragraph
              component="div"
              color="textSecondary"
            >
              <ReactMarkdown source={description} />
            </Typography>
          )}
        </AnimateOnSeen>
        <AnimateOnSeen
          animeConfig={{
            ...TRANSLATE_ANIME_CONFIG,
            targets: `.${className} .bullet`,
            delay: anime.stagger(100, { direction: "reverse" }),
          }}
        >
          {!!columns.length && (
            <Grid container spacing={2}>
              {columns.map((column, index) => (
                <Grid key={index} item xs={12} lg={12 / columns.length}>
                  <List dense>
                    {column.items.map((item, index) => (
                      <ListItem key={index} className="bullet">
                        <ListItemIcon>
                          <FontAwesomeIcon
                            icon={faCircle}
                            color={theme.palette.secondary.main}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <ReactMarkdown
                              source={item.content}
                              disallowedTypes={["paragraph"]}
                              unwrapDisallowed
                            />
                          }
                          primaryTypographyProps={{
                            variant: "body1",
                            color: "textSecondary",
                            component: "div",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          )}
          {children}
        </AnimateOnSeen>
      </Box>
    )
  }

  function renderImage() {
    return featuredmedia?.featuredimage?.source ||
      featuredmedia?.featuredvideo?.id ? (
      <AnimateOnSeen animeConfig={{ ...TRANSLATE_ANIME_CONFIG }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          position="relative"
        >
          <Box
            position="absolute"
            className={classes.background}
            bgcolor={
              md
                ? "inherit"
                : featuredmedia.background === "gray"
                ? colors.grayLightest
                : colors.white
            }
            width="100%"
            height="100%"
          />
          <Box
            maxWidth={640}
            width="100%"
            height="100%"
            position="relative"
            p={3}
          >
            {featuredmedia.background === "adornments" && (
              <React.Fragment>
                {sm && (
                  <Box
                    className={classes.topLeftBorder}
                    bgcolor={colors.shipperTMS}
                    position="absolute"
                    height="70%"
                    width="50%"
                    zIndex={-1}
                    top={0}
                    left={0}
                  />
                )}
                <Box
                  className={classes.bottomRightBorder}
                  position="absolute"
                  height={190}
                  width={190}
                  zIndex={-1}
                  bottom={-theme.spacing(1)}
                  right={0}
                  style={{
                    backgroundImage: `url(${dotsSquareImg})`,
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </React.Fragment>
            )}
            {featuredmedia.featuredimage?.source ? (
              <PreviewCompatibleImage
                className={`${classes.borderRadius} ${classes.fullWidth}`}
                type="fluid"
                image={featuredmedia.featuredimage.source}
                alt={featuredmedia.featuredimage.alt}
              />
            ) : (
              <Video
                className={classes.fullWidth}
                {...featuredmedia.featuredvideo}
              />
            )}
          </Box>
        </Box>
      </AnimateOnSeen>
    ) : (
      false
    )
  }

  return (
    <Box my={md ? 3 : 1} position="relative" className={className}>
      {!!featuredmedia && featuredmedia.background === "gray" && md && (
        <Box
          className={`${classes.background} ${
            featuredmedia.position === "left"
              ? classes.borderRightRadius
              : classes.borderLeftRadius
          }`}
          position="absolute"
          {...(featuredmedia.position === "left"
            ? {
                left: 0,
              }
            : { right: 0 })}
          zIndex={-1}
          height="100%"
          width="50%"
          bgcolor={colors.grayLightest}
        />
      )}
      <Container className={classes.noPadding} maxWidth="lg">
        <Box py={md ? 3 : 1}>
          <Grid container alignItems="center">
            {!!featuredmedia && (
              <React.Fragment>
                <Grid
                  item
                  xs={12}
                  md={featuredmedia.position === "top" ? 12 : 6}
                >
                  {featuredmedia.position === "left" ||
                  featuredmedia.position === "top" ||
                  !md
                    ? renderImage()
                    : renderText()}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={featuredmedia.position === "top" ? 12 : 6}
                >
                  {featuredmedia.position === "left" ||
                  featuredmedia.position === "top" ||
                  !md
                    ? renderText()
                    : renderImage()}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

Benefit.protoTypes = {
  className: PropTypes.string,
  title: PropTypes.array,
  description: PropTypes.string,
  featuredmedia: PropTypes.object,
  bullets: PropTypes.array,
  imageleft: PropTypes.bool,
  children: PropTypes.node,
}

export default Benefit
