import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import Box from "@material-ui/core/Box"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Box height="20px">
        <Typography align="center" variant="h6">
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </IconButton>
        ) : null}
      </Box>
    </MuiDialogTitle>
  )
})

export default DialogTitle
