import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import { withStyles } from "@material-ui/styles"
import anime from "animejs/lib/anime.es.js"
import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { TRANSLATE_ANIME_CONFIG } from "../constants"
import { colors } from "../theme"
import AnimateOnSeen from "./animate-on-seen"
import PreviewCompatibleImage from "./preview-compatible-image"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const styles = {
  gridItem: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    height: "100%",
  },
  cardActionArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  media: {
    position: "relative",
    width: 100,
    minHeight: 80,
    flexShrink: 0,
  },
}

class OtherProducts extends React.Component {
  animationsQueueMap = {}

  constructor(props) {
    super(props)

    this.state = {
      selectedCard: false,
      seen: false,
    }

    this.handleCardEnter = this.handleCardEnter.bind(this)
    this.handleCardLeave = this.handleCardLeave.bind(this)
  }

  handleCardEnter(index) {
    const { seen } = this.state

    if (seen) {
      this.setState({ selectedCard: index })

      anime.remove(`.other-products-section .bar-${index}`)
      anime({
        targets: `.other-products-section .bar-${index}`,
        width: "100%",
        duration: 400,
        easing: "easeInOutQuad",
      })
      anime.remove(`.other-products-section .card-${index}`)
      anime({
        targets: `.other-products-section .card-${index}`,
        translateY: -10,
        duration: 300,
      })

      anime.remove(`.other-products-section .learn-more-arrow-${index}`)
      anime({
        targets: `.other-products-section .learn-more-arrow-${index}`,
        translateX: 5,
        duration: 300,
      })
    }
  }

  handleCardLeave(index) {
    const { seen } = this.state

    if (seen) {
      this.setState({ selectedCard: null })

      anime.remove(`.other-products-section .bar-${index}`)
      anime({
        targets: `.other-products-section .bar-${index}`,
        width: "0%",
        duration: 400,
        easing: "easeInOutQuad",
      })

      anime.remove(`.other-products-section .card-${index}`)
      anime({
        targets: `.other-products-section .card-${index}`,
        translateY: 0,
        duration: 300,
      })

      anime.remove(`.other-products-section .learn-more-arrow-${index}`)
      anime({
        targets: `.other-products-section .learn-more-arrow-${index}`,
        translateX: 0,
        duration: 300,
      })
    }
  }

  render() {
    const { title, items, classes, width } = this.props
    const { selectedCard } = this.state

    return (
      <Box
        className="other-products-section"
        pt={isWidthUp("md", width) ? 10 : 5}
        pb={isWidthUp("md", width) ? 14 : 7}
      >
        <AnimateOnSeen animeConfig={{ ...TRANSLATE_ANIME_CONFIG }}>
          <Box mb={isWidthUp("md", width) ? 6 : 3}>
            <Typography
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {title}
            </Typography>
          </Box>
        </AnimateOnSeen>
        <AnimateOnSeen
          animeConfig={{
            ...TRANSLATE_ANIME_CONFIG,
            targets: `.other-products-section .${classes.card}`,
            delay: anime.stagger(100, { direction: "reverse" }),
            complete: () => {
              this.setState({ seen: true })
            },
          }}
        >
          <Grid container spacing={1} justify="center">
            {items.map((item, index) => (
              <Grid
                key={index}
                className={classes.gridItem}
                item
                xs={12}
                md={6}
              >
                <Box
                  position="relative"
                  height="100%"
                  overflow="hidden"
                  maxWidth={600}
                  onMouseEnter={() => this.handleCardEnter(index)}
                  onMouseLeave={() => this.handleCardLeave(index)}
                  p={2}
                >
                  <Card
                    className={`card-${index} ${classes.card}`}
                    raised={selectedCard === index}
                  >
                    <CardActionArea
                      className={classes.cardActionArea}
                      id={`Related Product Card - ${item.title}`}
                      to={`/${item.link}`}
                      component={AdapterLink}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection={
                          isWidthUp("md", width) ? "row" : "column"
                        }
                        flexGrow={1}
                      >
                        {item.thumbnail && item.thumbnail.source && (
                          <CardMedia className={classes.media}>
                            <Box
                              position="absolute"
                              left={-15}
                              top={isWidthUp("md", width) ? 0 : -5}
                              height="100%"
                              display="flex"
                              alignItems="center"
                            >
                              <PreviewCompatibleImage
                                type="fixed"
                                image={item.thumbnail.source}
                                alt={item.thumbnail.alt}
                                rawImageProps={{ width: 110 }}
                              />
                            </Box>
                          </CardMedia>
                        )}
                        <CardContent className={classes.content}>
                          <Box flexGrow="1" pt={isWidthUp("md", width) ? 3 : 0}>
                            <Typography
                              variant="h5"
                              color="textPrimary"
                              gutterBottom
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              gutterBottom
                            >
                              {item.description}
                            </Typography>
                          </Box>
                          <Typography variant="subtitle2" color="primary">
                            <strong>Learn more</strong>{" "}
                            <FontAwesomeIcon
                              className={`learn-more-arrow-${index}`}
                              icon={faArrowRight}
                              size="xs"
                            />
                          </Typography>
                        </CardContent>
                      </Box>
                      <Box
                        flexGrow={0}
                        flexShrink={0}
                        className={`bar-${index}`}
                        width={0}
                        height={5}
                        bgcolor={colors.link}
                      />
                    </CardActionArea>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </AnimateOnSeen>
      </Box>
    )
  }
}

OtherProducts.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
}

export default withWidth()(withStyles(styles)(OtherProducts))
