import PropTypes from "prop-types"
import React from "react"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import ReactMarkdown from "react-markdown"
import { makeStyles } from "@material-ui/core/styles"
import AnimateOnSeen from "./animate-on-seen"
import { TRANSLATE_ANIME_CONFIG } from "../constants"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))

const RichText = ({ content }) => {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth="md">
      <AnimateOnSeen animeConfig={{ ...TRANSLATE_ANIME_CONFIG }}>
        <Typography color="textPrimary" align="center" component="div">
          <ReactMarkdown source={content} />
        </Typography>
      </AnimateOnSeen>
    </Container>
  )
}

RichText.protoTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
}

export default RichText
