import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { graphql, Link as GatsbyLink } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import Action from "../components/action"
import Benefit from "../components/benefit"
import CTAButton from "../components/CTAButton"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import DialogTitle from "../components/dialog-title"
import Features from "../components/features"
import Hero from "../components/hero"
import OtherProducts from "../components/other-products"
import RichText from "../components/rich-text"
import PricingTable from "../components/pricing-table"
import Page from "../components/page"
import Testimonials from "../components/testimonials"
import Testimonial from "../components/testimonial"
import Video from "../components/video"
import VideoSection from "../components/video-section"
import { buildMeta } from "../helpers"
import testimonialBackgroundImg from "../img/testimonial-background.png"
import { colors } from "../theme"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const useStyles = makeStyles(theme => ({
  hero: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(11),
    },
  },
  testimonial: {
    backgroundImage: `url(${testimonialBackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  },
  topLeftBorder: {
    borderTopLeftRadius: 24,
  },
  background: {
    opacity: 0.6,
  },
  verticalDivider: {
    margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.common.black,
  },
  logosContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(6),

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  logoImg: {
    marginBottom: theme.spacing(3),
  },
  signUpButtonBenefitContainer: {
    marginTop: theme.spacing(1),
  },
  signUpButtonFeaturesContainer: {
    marginTop: theme.spacing(9),
    textAlign: "center",
  },
  signUpButtonEnlarger: {
    minWidth: 120,
    display: "inline-block",
  },
  signUpButton: {
    marginBottom: theme.spacing(1),
  },
}))

export const ProductTemplate = ({
  data: {
    hero,
    features,
    benefits,
    video,
    testimonials,
    richtexts,
    otherproducts,
    actions,
  },
  fields,
  onPrimaryActionVisibilityChange,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [isDemoOpen, setIsDemoOpen] = React.useState(false)
  const md = useMediaQuery(theme.breakpoints.up("md"))

  const heroCtas = []

  if (hero) {
    if (video && video.id) {
      heroCtas.push(
        <Box key={0} mr={1} mb={1} color={colors.cta} minWidth={135}>
          <CTAButton
            size="large"
            fullWidth
            className="content pcta-hero-watch-demo"
            id="Watch Demo - Primary - Hero"
            variant="outlined"
            color="inherit"
            onClick={() => {
              setIsDemoOpen(true)
            }}
          >
            Watch demo
          </CTAButton>
        </Box>
      )
    }
  }

  const isSmarthaulPricingPage =
    (fields && fields.slug) === "/products/smarthaul-tms-pricing/"
  const isSmarthaulLandingPage =
    (fields && fields.slug) === "/products/smarthaul-tms-solution/"
  const isDefaultPage = !isSmarthaulPricingPage && !isSmarthaulLandingPage

  const renderSmarthaulPricingSpecific = () => {
    return (
      <>
        {features && <Features {...features} />}
        {!!richtexts && !!richtexts[0] && (
          <div>
            <RichText {...richtexts[0]} />
          </div>
        )}
        {!!benefits && !!benefits.length && (
          <>
            <Container maxWidth="lg">
              <Grid container justify="center">
                {benefits.slice(0, 3).map((benefit, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <Benefit
                      key={index}
                      className={`benefit-section-${index}`}
                      {...benefit}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
            {!!benefits && !!benefits[3] && (
              <Benefit className={`benefit-section-${3}`} {...benefits[3]} />
            )}
          </>
        )}
        {!!richtexts && !!richtexts[1] && (
          <div>
            <RichText {...richtexts[1]} />
          </div>
        )}
        {!!benefits &&
          benefits
            .slice(4, benefits.length - 1)
            .map((benefit, index) => (
              <Benefit
                key={index}
                className={`benefit-section-${index}`}
                {...benefit}
              />
            ))}
        <PricingTable />
        {!!benefits &&
          benefits
            .slice(benefits.length - 1)
            .map((benefit, index) => (
              <Benefit
                key={index}
                className={`benefit-section-${index}`}
                {...benefit}
              />
            ))}
      </>
    )
  }

  const renderSmarthaulLandingSpecific = () => {
    const contentMax = Math.max(
      benefits?.length || 0,
      testimonials?.length || 0
    )
    const contentArray = []

    for (let i = 0; i < contentMax; i++) {
      if (benefits && benefits[i]) {
        contentArray.push(
          <Benefit
            key={`benefit-section-${i}`}
            className={`benefit-section-${i}`}
            {...benefits[i]}
          >
            {i === 0 && (
              <div className={classes.signUpButtonBenefitContainer}>
                <div className={classes.signUpButtonEnlarger}>
                  <Button
                    className={classes.signUpButton}
                    size="large"
                    variant="contained"
                    color="secondary"
                    component={AdapterLink}
                    to="sign-up-carrier"
                    fullWidth
                  >
                    Sign Up
                  </Button>
                </div>
                <Typography variant="body2" color="textSecondary">
                  *No credit card required.
                </Typography>
              </div>
            )}
          </Benefit>
        )
      }

      if (testimonials && testimonials[i]) {
        contentArray.push(
          <div key={`testimonial-section-${i}`} className={classes.testimonial}>
            <Container maxWidth="lg">
              <Box py={md ? 6 : 3} pb={md ? 20 : 10}>
                <Box position="relative">
                  <Box
                    className={classes.topLeftBorder}
                    bgcolor={colors.shipCars}
                    position="absolute"
                    height={220}
                    width={220}
                    // zIndex={-1}
                    top={-theme.spacing(2)}
                    left={-theme.spacing(2)}
                  />
                  <Testimonial {...testimonials[i]} />
                </Box>
              </Box>
            </Container>
          </div>
        )
      }
    }

    return (
      <>
        {contentArray}
        {features && (
          <Features {...features}>
            <div className={classes.signUpButtonFeaturesContainer}>
              <div className={classes.signUpButtonEnlarger}>
                <Button
                  className={classes.signUpButton}
                  size="large"
                  variant="contained"
                  color="secondary"
                  component={AdapterLink}
                  to="sign-up-carrier"
                  fullWidth
                >
                  Sign Up
                </Button>
              </div>
              <Typography variant="body2" color="textSecondary">
                *No credit card required.
              </Typography>
            </div>
          </Features>
        )}
      </>
    )
  }

  const renderDefault = () => {
    return (
      <>
        {features && <Features {...features} />}
        {!!benefits && !!benefits[0] && (
          <Benefit className={`benefit-section-${0}`} {...benefits[0]} />
        )}
        {testimonials && !!testimonials.length && (
          <Box className={classes.testimonial} my={6}>
            <Container maxWidth="lg">
              <Box py={md ? 6 : 3} pb={md ? 20 : 10}>
                <Box position="relative">
                  <Box
                    className={classes.topLeftBorder}
                    bgcolor={colors.shipCars}
                    position="absolute"
                    height={220}
                    width={220}
                    // zIndex={-1}
                    top={-theme.spacing(1)}
                    left={-theme.spacing(1)}
                  />
                  <Testimonials items={testimonials} />
                </Box>
              </Box>
            </Container>
          </Box>
        )}
        {!!benefits &&
          benefits
            .slice(1)
            .map((benefit, index) => (
              <Benefit
                key={index}
                className={`benefit-section-${index}`}
                {...benefit}
              />
            ))}
      </>
    )
  }

  return (
    <div>
      {hero && (
        <Hero
          className={classes.hero}
          {...hero}
          customCTAs={heroCtas}
          onPrimaryActionVisibilityChange={onPrimaryActionVisibilityChange}
        />
      )}
      {isSmarthaulPricingPage && renderSmarthaulPricingSpecific()}
      {isSmarthaulLandingPage && renderSmarthaulLandingSpecific()}
      {isDefaultPage && renderDefault()}
      {!!video && !!video.id && <VideoSection {...video} />}
      {!!video && !!video.id && (
        <Dialog
          open={isDemoOpen}
          onClose={() => {
            setIsDemoOpen(false)
          }}
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle
            onClose={() => {
              setIsDemoOpen(false)
            }}
          />
          <DialogContent>
            <Video {...video} />
          </DialogContent>
        </Dialog>
      )}
      {!!richtexts && !!richtexts[richtexts.length - 1] && (
        <div>
          <RichText {...richtexts[richtexts.length - 1]} />
        </div>
      )}
      {actions &&
        actions.map((action, index) => (
          <Action
            key={index}
            className={`action-section-${index}`}
            {...action}
          />
        ))}
      {otherproducts && !!otherproducts.length && (
        <Box position="relative">
          <Box
            className={classes.background}
            position="absolute"
            height="100%"
            width="100%"
            bgcolor={colors.grayLightest}
          />
          <Container maxWidth="lg">
            <OtherProducts title="Our Other Products" items={otherproducts} />
          </Container>
        </Box>
      )}
    </div>
  )
}

const Product = ({
  data: { headerData, footerData, productData, subscribeData, siteData },
  location,
}) => {
  const [showNavPrimaryAction, setShowNavPrimaryAction] = React.useState(false)
  let { header = {}, hero, seo, link, footer = {} } = productData.frontmatter
  const { siteUrl } = siteData.siteMetadata

  header = header || {}
  footer = footer || {}

  let primaryAction

  if (hero && hero.ctas) {
    const primaryCTA = hero.ctas.find(({ primary }) => primary)

    if (primaryCTA) {
      const { action, label, variant } = primaryCTA

      primaryAction = (
        <CTAButton
          fullWidth
          variant={variant}
          color="primary"
          component={action && action.startsWith("/") ? AdapterLink : "a"}
          {...(action && action.startsWith("/")
            ? {
                to: action,
              }
            : {
                href: action,
              })}
        >
          {label}
        </CTAButton>
      )
    }
  } else {
    primaryAction = null
  }

  const headerAction = (
    <Box color={colors.cta}>
      {header.cta ? (
        <CTAButton
          size="large"
          fullWidth
          variant={header.cta.variant}
          color="primary"
          component={
            header.cta.action && header.cta.action.startsWith("/")
              ? AdapterLink
              : "a"
          }
          {...(header.cta.action && header.cta.action.startsWith("/")
            ? {
                to: header.cta.action,
              }
            : {
                href: header.cta.action,
              })}
        >
          {header.cta.label}
        </CTAButton>
      ) : (
        <CTAButton
          size="large"
          fullWidth
          variant="outlined"
          color="inherit"
          href="/app/login"
        >
          Login
        </CTAButton>
      )}
    </Box>
  )

  const handlePrimaryActionVisibilityChange = isVisible => {
    setShowNavPrimaryAction(!isVisible)
  }

  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        meta={[
          ...buildMeta(
            siteData.siteMetadata,
            seo,
            hero ? hero.featuredimage : null,
            `/${link}`
          ),
          ...(seo.metatags ? seo.metatags : []),
        ]}
      >
        {link && <link rel="canonical" href={`${siteUrl}/${link}`} />}
        {seo.schemamarkup && (
          <script type="application/ld+json">{seo.schemamarkup}</script>
        )}
      </Helmet>
      <Page
        primaryAction={
          showNavPrimaryAction ? primaryAction || headerAction : headerAction
        }
        logo={
          header.logo && header.logo.full && header.logo.small
            ? { ...header.logo, link: location.pathname }
            : null
        }
        headerData={header.hidenavigation ? { frontmatter: {} } : headerData}
        footerData={footerData}
        hideFooter={footer.hide}
      >
        <ProductTemplate
          data={productData.frontmatter}
          fields={productData.fields}
          onPrimaryActionVisibilityChange={handlePrimaryActionVisibilityChange}
        />
        {/* <Subscribe data={subscribeData} /> */}
      </Page>
    </React.Fragment>
  )
}

export default Product

export const pageQuery = graphql`
  query ProductBySlug($slug: String!) {
    ...PageFragment
    ...SubscribeFragment
    siteData: site {
      siteMetadata {
        siteUrl
        env
      }
    }
    productData: markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        link
        seo {
          title
          description
          keywords
          schemamarkup
          metatags {
            name
            content
          }
        }
        header {
          logo {
            full {
              childImageSharp {
                fixed(height: 32) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            small {
              childImageSharp {
                fixed(height: 32) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            alt
          }
          hidenavigation
          cta {
            label
            action
            variant
          }
        }
        hero {
          title {
            label
            variant
          }
          description
          featuredimage {
            source {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          ctas {
            primary
            label
            action
            variant
          }
        }
        features {
          title
          items {
            title
            thumbnail {
              alt
              source {
                childImageSharp {
                  fixed(height: 105) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
        benefits {
          description
          title
          featuredmedia {
            background
            position
            featuredimage {
              source {
                childImageSharp {
                  fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            featuredvideo {
              id
              overlayimage {
                source {
                  childImageSharp {
                    fluid(maxHeight: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          bullets {
            columns
            items {
              content
            }
          }
        }
        video {
          id
        }
        actions {
          title
          description
          logos {
            source {
              childImageSharp {
                fixed(height: 60) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            alt
          }
          ctas {
            label
            action
            variant
            buttonimage {
              source {
                childImageSharp {
                  fixed(height: 60) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
          }
        }
        testimonials {
          content
          thumbnail {
            source {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          author {
            jobtitle
            name
          }
          logo {
            active {
              publicURL
            }
            inactive {
              publicURL
            }
            alt
          }
        }
        richtexts {
          content
        }
        otherproducts {
          title
          description
          link
          thumbnail {
            source {
              childImageSharp {
                fixed(width: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            alt
          }
        }
        footer {
          hide
        }
      }
    }
  }
`
