import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import anime from "animejs/lib/anime.es.js"
import ReactMarkdown from "react-markdown"
import PropTypes from "prop-types"
import { default as React } from "react"
import { TRANSLATE_ANIME_CONFIG } from "../constants"
import AnimateOnSeen from "./animate-on-seen"
import PreviewCompatibleImage from "./preview-compatible-image"

const Features = ({ items, title, children }) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up("sm"))

  if (!title && (!items || !items.length)) {
    return false
  }

  return (
    <Container maxWidth="lg" className="features-section">
      <Box pt={3} pb={6}>
        <AnimateOnSeen animeConfig={{ ...TRANSLATE_ANIME_CONFIG }}>
          <Box mb={sm ? 8 : 3}>
            <Typography variant="h2" align="center" color="textPrimary">
              {title}
            </Typography>
          </Box>
        </AnimateOnSeen>
        <AnimateOnSeen
          animeConfig={{
            ...TRANSLATE_ANIME_CONFIG,
            targets: ".features-section .feature",
            delay: anime.stagger(100, { direction: "reverse" }),
          }}
        >
          <Grid container spacing={3} justify="center">
            {items.map((step, index) => (
              <Grid
                key={index}
                className="feature"
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                {step.thumbnail && (
                  <Box display="flex" justifyContent="center">
                    <PreviewCompatibleImage
                      type="fixed"
                      image={step.thumbnail.source}
                      alt={step.thumbnail.alt}
                      rawImageProps={{ height: 105 }}
                    />
                  </Box>
                )}
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                >
                  <ReactMarkdown
                    source={step.title}
                    renderers={{ paragraph: "div" }}
                  />
                </Typography>
              </Grid>
            ))}
          </Grid>
          {children}
        </AnimateOnSeen>
      </Box>
    </Container>
  )
}

Features.protoTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.node,
}

export default Features
