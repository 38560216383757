import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import { colors } from "../theme"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  tableCell: {
    border: `2px solid ${colors.grayDark}`,
  },
  th: {
    fontWeight: "bold",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  tr: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

const rows = [
  {
    monthly: "Price",
    tier1: "$48.76",
    tier2: "$198.97",
    tier3: "$499.56",
  },
  {
    monthly: "Number of messages",
    tier1: "1,000",
    tier2: "5,000",
    tier3: "15,000",
  },
  {
    monthly: "Additional messages",
    tier1: "$0.030",
    tier2: "$0.025",
    tier3: "$0.020",
  },
  {
    monthly: "Number of dispatchers",
    tier1: "5",
    tier2: "15",
    tier3: "30",
  },
  {
    monthly: "Additional dispatchers",
    tier1: "$8.00",
    tier2: "$7.00",
    tier3: "$5.00",
  },
  {
    monthly: "Number of drivers",
    tier1: "Unlimited",
    tier2: "Unlimited",
    tier3: "Unlimited",
  },
  {
    monthly: "Mass messages",
    tier1: "X",
    tier2: "X",
    tier3: "X",
  },
  {
    monthly: "Scheduled messages",
    tier1: "X",
    tier2: "X",
    tier3: "X",
  },
]

const PricingTable = () => {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth="lg">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.tableCell }}>
                (Monthly)
              </TableCell>
              <TableCell
                classes={{ root: classes.tableCell, head: classes.th }}
              >
                Tier 1
              </TableCell>
              <TableCell
                classes={{ root: classes.tableCell, head: classes.th }}
              >
                Tier 2
              </TableCell>
              <TableCell
                classes={{ root: classes.tableCell, head: classes.th }}
              >
                Tier 3
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow classes={{ root: classes.tr }} key={row.monthly}>
                <TableCell
                  classes={{ root: classes.tableCell }}
                  component="th"
                  scope="row"
                >
                  {row.monthly}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }}>
                  {row.tier1}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }}>
                  {row.tier2}
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }}>
                  {row.tier3}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

PricingTable.protoTypes = {}

export default PricingTable
